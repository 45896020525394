<template>
	<v-container
		fill-height
        fluid>
		<v-row
			align="center"
			justify="center">
			<v-col
				cols="11"
				sm="9"
				md="8">
				<v-card color="transparent"
					elevation="0">
					<h1 class="display-1 font-weight-black font-italic primary--text my-10">Вхід</h1>
					<validation-observer
						ref="observer"
						v-slot="{ invalid, validated, handleSubmit }">
						<v-form @submit.prevent="handleSubmit(submit)">
							<v-row>
								<v-col
									cols="12"
									md="6"
									class="py-0">
									<v-text-field-validatable
										v-model="user.email"
										type="email"
										label="Емейл"
										rules="required|email"
										required>
									</v-text-field-validatable>
								</v-col>

								<v-col
									cols="12"
									md="6"
									class="py-0">
									<v-text-field-validatable
										v-model="user.password"
										type="password"
										label="Пароль"
										rules="required|min:8"
										required>
									</v-text-field-validatable>
								</v-col>
								
							</v-row>

							<v-row class="mb-10 align-center">
								<v-col
									cols="12">
									<v-btn
										type="submit"
										color="btnBg"
										class="btnTxt--text"
										v-ripple="{ class: 'success--text' }"
										:loading="loading"
										:disabled="invalid">
										Увійти
									</v-btn>
								</v-col>
							</v-row>
							
							<small class="grey--text mb-10">Ще не маєш акаунту? Тоді тобі сюди: <router-link :to="{ name: 'registration' }">РЕЄСТРАЦІЯ</router-link> </small>
							<br>
							<!-- <small class="grey--text mb-10">Забув(-ла) пароль? <router-link :to="{ name: 'forgot-password' }">СКИНУТИ ПАРОЛЬ</router-link> </small> -->

						</v-form>
					</validation-observer>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	data: () => ({
		user: {
			email: '',
			password: '',
		},
		loading: false,
	}),
	methods: {
		...mapActions('notification', [
			'notify',
		]),
		...mapActions('auth', [
			'login',
		]),
		async submit() {
			try {
				this.loading = true
				await this.login(this.user)
				this.$router.replace({
					name: 'home',
				})
			} finally {
				this.loading = false
			}
		},
	},
}
</script>